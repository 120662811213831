
import { Container, Grid} from "@nextui-org/react";

import {Card1} from './card1';
import {Card2} from './card2';
import {Card3} from './card3';

import {Card4} from './card4';



function CardLayout(props) {
  return (
    <Container>
    
    <Grid.Container gap={2} justify="center">
      <Grid xs={12} sm={6}  lg={4} xl={3}>
      <Card1 />
      </Grid >
      <Grid xs={12} sm={6} lg={4} xl={3}>
      <Card2 />
      </Grid>
      <Grid xs={12} sm={6}  lg={4} xl={3}>
      <Card3 />
      </Grid>
      <Grid xs={12} sm={6}  lg={4} xl={3}>
      <Card4 />
      </Grid>
      <Grid xs={12} sm={6}  lg={4} xl={3}>
      
      </Grid>
    </Grid.Container>
    </Container>
      
  );
}

export default CardLayout;
