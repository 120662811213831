
import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';




import {  Text, Link } from "@nextui-org/react";


export const Tline = () => (
  <Timeline position='alternate'
      
      
    >
      <TimelineItem>
      <TimelineOppositeContent >
      <Text h3>Wovenware a Maxar Company</Text>
          <Text h4>Software Developer 1</Text>
          <Text color="#9E9E9E">June 2023 - Present</Text>
          <Text></Text>
        </TimelineOppositeContent>
        
        <TimelineSeparator>
          <TimelineDot  color='error'>
          <WorkIcon/>
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'error.main' }}/>
        </TimelineSeparator>
        <TimelineContent>
          
          
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent >
      <Text h3>Python Certificate</Text>
          <Text h4>PCEP - Certified Entry Level Python Programmer</Text>
          <Link href="https://www.credly.com/badges/3e6c382c-69b4-43ea-9ea7-192cb6dbf6b9/public_url?trk=public_profile_see-credential" isExternal>Credly Badge</Link>
          <Text color="#9E9E9E">July 2023</Text>
          <Text></Text>
        </TimelineOppositeContent>
        
        <TimelineSeparator>
          <TimelineDot  color='primary'>
          <SchoolIcon/>
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }}/>
        </TimelineSeparator>
        <TimelineContent>
          
          
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineOppositeContent >
      <Text h3 >QSR Champs / El Meson Sandwiches</Text>
          <Text h4> Software Engineer Intern</Text>
          <Text color="#9E9E9E">March 2022 - November 2022</Text>
          <p>Designed and implemented an application using Python, HTML, CSS and Javascript to scan and validate QR coupons for the company's in-store POS systems.</p>
        
        </TimelineOppositeContent>
        <TimelineSeparator >
          <TimelineDot color="error"  >
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'error.main' }}/>
          
        </TimelineSeparator>
        <TimelineContent>
          
          
          </TimelineContent>
      </TimelineItem>
      
     
      <TimelineItem>
      <TimelineOppositeContent >
      <Text h3>University of Puerto Rico Mayagüez</Text>
          <Text h4>BS Software Engineering</Text>
          <Text color="#9E9E9E">August 2017 - May 2023</Text>
          <Text>Courses: Data Structures, Algorithms, Database Systems, Operating Systems, Object-Oriented Programming in Java.</Text>
        </TimelineOppositeContent>
        
        <TimelineSeparator>
          <TimelineDot  color='primary'>
          <SchoolIcon/>
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'primary.main' }}/>
        </TimelineSeparator>
        <TimelineContent>
          
          
        </TimelineContent>
      </TimelineItem>
      
    </Timeline>
  );
