
import { Badge, Button, Link, Text, Card, Radio, Container, Spacer, Row, Col} from "@nextui-org/react";



export const Card1 = () => (
    <Card  isHoverable isPressable as={Link}
    href="https://github.com/DATABASE-FALL-2022/db-mail2022-cems" css={{ w: "100%", h: "425px"}}>
        
        <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
        <Col>
            <Text size={12} weight="bold" transform="uppercase" color="#9E9E9E">
            Database Course Project
            </Text>
            <Text h3 color="white">
            CEMS Mail App
            </Text>
        </Col>
        </Card.Header>
        <Card.Body css={{ p: 0 }}>
        <Card.Image
            showSkeleton
            src="/images/cemsemail.JPG"
            objectFit="contain"
            width="100%"
            height="100%"
            alt="Relaxing app background"
        />
        </Card.Body>
        <Card.Footer
        isBlurred
        css={{
            position: "absolute",
            
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1,
        }}
        >
        <Container>
                <Row>
                    <Badge color="white" variant="bordered" size={"sm"}>Flask</Badge>
                    <Spacer x={0.2}/>
                    <Badge color="white" variant="bordered" size={"sm"}>SQL</Badge>
                    <Spacer x={0.2}/>
                    <Badge color="white" variant="bordered" size={"sm"}>React</Badge>
                    <Spacer x={0.2}/>
                    <Badge color="white" variant="bordered" size={"sm"}>Semantic UI</Badge>
                    

                </Row>
                <Spacer y={0.2}/>
                <Row align='center'>
                    <Col>

                        <Text weigth="bold">Email platform for sending and receiving emails to users from the same platform. </Text>
                        <Link isExternal>GitHub</Link>
                    
                    </Col>
                    

                </Row>
            </Container>
        </Card.Footer>
  </Card>
      
  );
