
import { Badge, Button, Link, Text, Card, Radio, Container, Spacer, Row, Col} from "@nextui-org/react";




export const Card3 = () => (
    <Card isHoverable   css={{ w: "100%", h: "425px" }}>
        <Card.Header  css={{ position: "absolute", zIndex: 1, top: 5 }}>
        <Col>
            <Text size={12} weight="bold" transform="uppercase" color="#9E9E9E">
            Capstone Project
            </Text>
            <Text h3 color="white">
            Apartamentazo
            </Text>
        </Col>
        </Card.Header>
        
        <Card.Body css={{ p: 0 }}>
            
        <Card.Image
            showSkeleton
            src="/images/apartamentazo.jpg"
            objectFit="contain"
            width="100%"
            height="100%"
            alt=""
        />
        
        </Card.Body>
        
        <Card.Footer
        isBlurred
        css={{
            position: "absolute",
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1,
        }}
        >
            <Container>
                <Row>
                    <Badge color="white" variant="bordered" size={"sm"}>Flask</Badge>
                    <Spacer x={0.2}/>
                    <Badge color="white" variant="bordered" size={"sm"}>React</Badge>
                    <Spacer x={0.2}/>

                    <Badge color="white" variant="bordered" size={"sm"}>TypeScript</Badge>
                    <Spacer x={0.2}/>

                    <Badge color="white" variant="bordered" size={"sm"}>TailwindCSS</Badge>


                    <Spacer x={0.2}/>
                    <Badge color="white" variant="bordered" size={"sm"}>PostgreSQL</Badge>
                    

                </Row>
                <Spacer y={0.2}/>
                <Row align='center'>
                    <Col>

                        <Text weigth="bold">App for university students looking for housing.</Text>
                    
                    </Col>
                    

                </Row>
            </Container>
        </Card.Footer>
  </Card>
      
  );
