
import { Badge, Button, Link, Text, Card, Radio, Container, Grid, Row, Col, Spacer} from "@nextui-org/react";




export const Card4 = () => (
    <Card css={{ w: "100%", h: "425px"}} isHoverable>
        <Card.Header  css={{ position: "absolute", zIndex: 1, top: 5 }}>
        <Col>
            <Text size={12} weight="bold" transform="uppercase" color="#9E9E9E">
            Advanced Programming Course
            </Text>
            <Text h3 color="white">
            Minesweeper
            </Text>
        </Col>
        </Card.Header>
        <Card.Body css={{ p: 0 }}>
        <Card.Image
            showSkeleton
            src="/images/minesweeper.png"
            objectFit="contain"
            width="100%"
            height="100%"
            alt="Relaxing app background"
        />
        
        </Card.Body>
        <Card.Footer
        isBlurred
        css={{
            position: "absolute",
            
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1,
        }}
        >
            <Container>
                <Row>
                    <Badge color="white" variant="bordered" size={"sm"}>Java</Badge>
                    

                </Row>
                <Spacer y={0.2}/>
                <Row align='center'>
                    <Col>

                        <Text weigth="bold">Java implementation of famous Minesweeper desktop game.</Text>
                        
                    </Col>
                    

                </Row>
            </Container>
            
        </Card.Footer>
  </Card>
      
  );
