import React from "react";
import { Navbar, Image } from "@nextui-org/react";
import  {Layout}  from "./Layout.js";
import { SocialIcon } from 'react-social-icons';
import { useEffect, useState } from 'react';
import { createTheme, NextUIProvider } from '@nextui-org/react';
import { Analytics } from '@vercel/analytics/react';

const navColor = '#121c2b';


const darkTheme = createTheme({
  type: 'dark',
  theme:{
    colors:{
    error: '#b3001b',
    darkRed: '#5A000E',
    background: '#0b161e',
    backgroundAlpha: navColor,
    backgroundContrast: navColor
  },
  space: {},
  fonts: {}
  }
});


function Position(obj) {
  var currenttop = 0;
  if (obj.offsetParent) {
    do {
      currenttop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [currenttop];
  }
}

export default function App() {

  const [navStates, setNavStates] = useState({ home: true, experiences: false, projects: false });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <NextUIProvider theme={darkTheme}>
      <Layout >
        <Navbar variant={"floating"} css={{borderColor: "$background"}}  >
          <Navbar.Brand >
            <Image 
              showSkeleton
              height={50}
              width={50}
              src="/ECLogo.png"
              alt="Default Image"
              objectFit="contain"
            />

          </Navbar.Brand>

          <Navbar.Content activeColor={"error"} variant={"highlight-solid-rounded"} hideIn={'xs'}>

            <Navbar.Link isActive={navStates.home} onPress={() => {
              console.log('home pressed');
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              setNavStates({ home: true, experiences: false, projects: false });
            }}>HOME</Navbar.Link>
            <Navbar.Link isActive={navStates.experiences} onPress={() => {
              console.log('experiences pressed');
              window.scrollTo({ top: Position(document.getElementById('experiences')), left: 0, behavior: 'smooth' });
              setNavStates({ home: false, experiences: true, projects: false });
            }}>EXPERIENCE</Navbar.Link>

            <Navbar.Link isActive={navStates.projects}
              onPress={() => {
                console.log('projects pressed');
                window.scrollTo({ top: Position(document.getElementById('projects')), left: 0, behavior: 'smooth' });
                setNavStates({ home: false, experiences: false, projects: true });
              }}
            >PROJECTS
            </Navbar.Link>
          </Navbar.Content>
          <Navbar.Content >
            <Navbar.Item>
              <SocialIcon url="https://github.com/elliotcardona" bgColor={navColor} fgColor='white' />
            </Navbar.Item>

            <Navbar.Item>
              <SocialIcon url="https://www.linkedin.com/in/elliot-cardona-pe%C3%B1a-078145259" bgColor={navColor} fgColor='#0072b1' />
            </Navbar.Item>
          </Navbar.Content>
        </Navbar>
      </Layout>
      <Analytics />
    </NextUIProvider>
    
  )
}
