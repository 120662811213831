
import { Text, Container, Image, Row, Col, Button, Link, Spacer } from "@nextui-org/react";
import Typewriter from 'typewriter-effect';



const description = "I am a web developer with a passion for creating engaging and innovative websites. Always expanding my knowledge in new technologies.";

function Intro(props) {
  return (
    <Container md>
      <Row align="center">
        <Col >
          <Text
            h2
            size={50}
            css={{
              color: '$error'

            }}
            weight="bold"
          >
            Hi!
          </Text>
          <Text
            h2
            size={50}

            weight="bold"
          >
            <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString('I\'m Elliot Cardona')
                
                .pauseFor(5000)
                .deleteAll()

                
                .start();
            }} options={{
              autoStart: true,
              loop: true,
            }}
          />
          </Text>
          <Text
            h2
            size={40}
            css={{
              color: "$error"
            }}
            weight="bold"
          >
            Software Engineer
          </Text>
          
          <Text
            h4>

            {description}
          </Text>
          <Link align="center" href="/docs/Resume Elliot J Cardona Pena.pdf"><Button color={"error"} size={"md"} ><Text weight={"bold"}>RESUME</Text></Button></Link>
        </Col>
        <Col>
          <Image showSkeleton  src="rocket.gif" objectFit="contain"></Image>
        </Col>
      </Row>








    </Container>









  );

}

export default Intro;
