import Intro from "./welcome.js";
import { Box } from "./Box.js";

import { Navbar, Button, Image, Text, Card, Col, Container, Spacer, Row } from "@nextui-org/react";
import Divider from '@mui/material/Divider';
import CardLayout from "./card-layout.js";
import { Tline } from "./TimeLine.js";
import Timeline from "@mui/lab/Timeline";

export const Layout = ({ children }) => (
  <Box
    css={{
      maxW: "100%",

    }}
  >
    {children}
    <Spacer y={2} />
    <Intro />
    <Spacer y={10} />
    <Box id="experiences" css={{ backgroundColor: "$backgroundAlpha", maxW: "100%" }} >
      <Divider/>
      <Container >

        <Spacer y={5} />

        <Card align={"center"} variant="flat" css={{ backgroundColor: "$backgroundAlpha" }}>

          <Card.Header >
            <Container alignItems="center"><Text h2 color="white">
              Experience and Studies
            </Text>
            </Container>
          </Card.Header>

          <Tline />
         

      </Card>
      </Container>
      <Spacer y={12}/>
        </Box>
      <Box id="projects" css={{ backgroundColor: "$background", maxW: "100%" }} >
      <Divider/>
      <Container>
      <Spacer y={5}/>
      <Card variant="flat" css={{ backgroundColor: "$background" }}>

        <Card.Header >
          <Container alignItems="center"><Text align={"center"} h2 color="white">
            Projects
          </Text></Container>

        </Card.Header>

        <CardLayout />
      </Card>
      <Spacer y={2} />
      </Container>
    </Box>
    <Container align="center" css={{paddingBottom: 25}}>
      <Image src="ECLogo.png" height={50}></Image>
    </Container>
  </Box>
);
